import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", {
    attrs: {
      "title": "Add Tracking Status"
    }
  }, [_c("text-input", {
    key: "code",
    attrs: {
      "form-item": "",
      "rules": "required|max:123",
      "label": "Code",
      "max-length": 123,
      "span": 24
    }
  }), _c("text-input", {
    key: "status",
    attrs: {
      "form-item": "",
      "label": "Status",
      "max-length": 256,
      "span": 24
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateTrackingStatus",
  data() {
    return {};
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateTrackingStatus = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "create-tracking-status"
  }, [_c("resource", {
    attrs: {
      "name": "export-order.manage-tracking-status",
      "api-url": _vm.apiUrl,
      "redirect-route": "/export-orders/tracking-status"
    }
  }, [_c("create-tracking-status")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateTrackingStatus
  },
  data() {
    return {
      CreateTrackingStatus,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
